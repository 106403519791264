import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Iframe from 'react-iframe';
import frameStyles from './WeatherstemStyles';



const useStyles = makeStyles({
  wrapper: {
    height: 'auto',
    padding: '4% 0',
    background: '#edf0ee',
  },
  content: {
    textAlign: 'center',
  },
});

export default function Weatherstem() {
  const classes = useStyles();
  const frame = frameStyles();

  return (
    <Grid id='weatherstem' container className={classes.wrapper}>
      <Grid item xs={0} lg={2}></Grid>
      <Grid item container justify='center' display='grid' lg={8}>
        <Typography  variant='h3' mb={4} align='center'>
            Alachua County WeatherStem Stations
        </Typography>
        <Iframe url="https://weatherstemwidget.alachuacounty.us/"
            width="100%"
            id=""
            className={frame.iframe}
            display="block"
            frameBorder={0}/>
      </Grid>
      <Grid item xs={0} lg={2}></Grid>
    </Grid>
  );
}
