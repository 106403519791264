import { Grid, Typography, Checkbox, FormGroup, FormControlLabel, Alert, Box, LinearProgress} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Geocode from 'react-geocode';

import Map from '../components/Map';
import { titleContext } from '../contexts/TitleContext';
import DisasterRecoveryTable from '../components/Tables/DisasterRecovery';
import { incidentsContext } from '../contexts/IncidentsContext';
import { makeStyles } from '@mui/styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import clsx from 'clsx';

Geocode.setApiKey(`AIzaSyBRbdKmyFU_X9r-UVmsapYMcKDJQJmQpAg`);
Geocode.setLocationType('ROOFTOP');


const headCells = [
    {
      id: 'dataid',
      label: '',
    },
    {
      id: 'Category',
      label: 'Type',
    },
    {
      id: 'LocationName',
      label: 'Location',
    },
    {
      id: 'Location',
      label: 'Address',
    },
    {
      id: 'Details',
      label: 'Additional Details',
    },
  ];

  const useStyles = makeStyles((theme) => ({
    statusSandbags: {
      background: theme.palette.sandbags.main,
    },
    statusCoolingCenter: {
      background: theme.palette.coolingcenter.main,
    },
    statusTarpDistribution: {
      background: theme.palette.tarpdistribution.main,
    },
    statusDeviceChargingLocation: {
      background: theme.palette.devicecharginglocation.main,
    },
    statusHotMealDistribution: {
      background: theme.palette.hotmealdistribution.main,
    },
    statusDisasterRecoveryCenter: {
      background: theme.palette.disasterrecoverycenter.main,
    },
    statusDSNAP: {
      background: theme.palette.dsnap.main,
    },
    statusPointOfDistribution: {
      background: theme.palette.pointofdistribution.main,
    },
    statusFeedingDistributionSite: {
      background: theme.palette.feedingdistributionsite.main,
    },
    statusGrey: {
      background: theme.palette.grey.main,
    },
    spanStyles: {
      color: theme.palette.white.main,
      display: 'block',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'Uppercase',
      padding: '0 4px'
    },
  }));

export default function DisasterRecoveryMap({ incidentIndex }) {

    const { updatePageTitle, updatePageHeading } = useContext(titleContext);
    const { incidents } = useContext(incidentsContext);
    const [center, setCenter] = useState({ lat: 29.651634, lng: -82.324829 });
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [disasterRecoveryLocationData, setDisasterRecoveryLocationData] = useState([]);
    const [allRecoveryLocationData, setAllRecoveryLocationData] = useState();
    const [siteTypes, setSiteTypes]= useState([]);
    const [selectedSites, setSelectedSites]= useState([]);

    const classes = useStyles();
    const styleValues = {
      FeedingDistributionSite: classes.statusFeedingDistributionSite,
      Sandbags: classes.statusSandbags,
      CoolingCenter: classes.statusCoolingCenter,
      TarpDistribution:classes.statusTarpDistribution,
      DeviceChargingLocation:classes.statusDeviceChargingLocation,
      HotMealDistribution:classes.statusHotMealDistribution,
      DisasterRecoveryCenter:classes.statusDisasterRecoveryCenter,
      DSNAP:classes.statusDSNAP,
      PointOfDistribution:classes.statusPointOfDistribution,
      FeedingDistributionSite:classes.statusFeedingDistributionSite
    }

    let url;
  
    if(process.env.REACT_APP_ENV == 'local'){
      url = process.env.REACT_APP_LOCAL_URL
    } else {
      url = 'https://api.alachuacounty.us/incidents-api'
    }
  
    const getDisasterRecoveryCentersLocationData = async () => {
      try {
        const test = await axios.get(`${url}/distributionLocations/activeDisasterRecoveryLocations`);
  
        for (let index in test.data[0]) {
          const response = await Geocode.fromAddress(test.data[0][index].Location);
          if (response.results[0]) {
            const { lat, lng } = response.results[0].geometry.location;
            test.data[0][index].latitude = lat;
            test.data[0][index].longitude = lng;
            test.data[0][index].dataid = test.data[0][index].MondayID;
            test.data[0][index].label= test.data[0][index].LocationName;
            test.data[0][index].address = test.data[0][index].Location;
          }
        }
        const exSiteTypes = [...new Set(test.data[0].map(item=> item.Category))];
        const allSiteTypes = exSiteTypes.map(type=>type.toLowerCase());
        setSiteTypes(exSiteTypes);
        setSelectedSites(allSiteTypes);
        setDisasterRecoveryLocationData(test.data[0]);
        setAllRecoveryLocationData(test.data[0])

      } catch (error) {
        console.log(error);
      }
    };

    const updateMapCenter = (lat, lng, id) => {
        setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
        updateSelectedMarker(id);
      };
    
      const updateSelectedMarker = (id) => {
        setSelectedMarker(id);
      };

    useEffect(() => {
        getDisasterRecoveryCentersLocationData();
        updatePageTitle(`${incidents[incidentIndex].name} | Disaster Recovery`);
        updatePageHeading(incidents[incidentIndex].name);
      }, []);

      const handleSiteFilter =(type)=>{
        const newSelection = selectedSites.includes(type.toLowerCase())? selectedSites.filter(curr => curr !== type): [...selectedSites, type];
        setSelectedSites(newSelection);

        let locationsToAdd = [];
        newSelection.forEach((selection)=> {
            allRecoveryLocationData.forEach((location)=> {
                if(location.Category.toLowerCase() == selection){
                    locationsToAdd.push(location);
                }
            })
        })
        setDisasterRecoveryLocationData(locationsToAdd);
       };

    return (
    <Grid container>
    {disasterRecoveryLocationData  ? (
      <>
        <Grid item xs={12}>
          <Map data={disasterRecoveryLocationData} center={center} selectedMarker={selectedMarker} />
        </Grid>
        <Grid item xs={12} pt={2}> 
              <Box
              component="section" 
              sx={{ borderRadius:'8px', boxShadow:3, width:'100%'}}>   
              <Box p={2}>  
              <Grid container>
                <Grid item xs={12} sm={1}> 
                   <Typography sx={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}> <FilterAltIcon></FilterAltIcon> Filter: </Typography>    
                </Grid>
                <Grid item xs={12} sm={11} >
                    <FormGroup sx={{display:'flex', flexDirection:'row'}}>
                      {siteTypes.map(type => (
                    <FormControlLabel 
                    key={type} 
                    control={<Checkbox  
                      size="medium"
                      checked={selectedSites.includes(type.toLowerCase())}
                      onChange={()=> handleSiteFilter(type.toLowerCase())}/>} 
                      label={      <span
                        className={clsx(
                          classes.spanStyles,
                          styleValues[type.replace(/\s/g, '')]
                            ? styleValues[type.replace(/\s/g, '')]
                            : classes.statusGrey
                        )}
                      >
                        {type}
                      </span>} /> ))}
                    </FormGroup>
                </Grid>
              </Grid>
              </Box>
              </Box> 
            </Grid>

        <Grid item xs={12}>
          <DisasterRecoveryTable headCells={headCells} rows={disasterRecoveryLocationData} updateMapCenter={updateMapCenter} />
        </Grid>
      </>
    ) : (
      <Grid item xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          There are currently no centers open. This page will be updated as the incident progresses. Please check again later for recovery resources.
        </Typography>
      </Grid>
    )}
  </Grid>
    )
}