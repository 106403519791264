import { makeStyles } from '@mui/styles';

const frameStyles = makeStyles((theme) => ({
    iframe: {
        [theme.breakpoints.up('lg')]: {
            height: '800px',
          },
        [theme.breakpoints.only('md')]: {
            height: '800px',
          },
        [theme.breakpoints.up('widget')]: {
            height: '800px',
          },
        [theme.breakpoints.down('widget')]: {
            height: '1050px',
          },
          [theme.breakpoints.down('widgetxs')]: {
            height: '1450px',
          },
      },
}));

export default frameStyles;
