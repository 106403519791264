import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  statusGreen: {
    background: theme.palette.green.main,
  },
  statusRed: {
    background: theme.palette.red.main,
  },
  spanStyles: {
    color: theme.palette.white.main,
    display: 'block',
    fontWeight: 'bold',
    textTransform: 'Uppercase',
  },
}));

function descendingComparator(a, b, orderByField) {
  if (b[orderByField] < a[orderByField]) {
    return -1;
  }
  if (b[orderByField] > a[orderByField]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderByField) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderByField)
    : (a, b) => -descendingComparator(a, b, orderByField);
}

export default function DisasterRecovery({ headCells, rows, updateMapCenter }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderByField, setOrderByField] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderByField === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderByField(property);
  };

  return (
    <>
    {rows.length ? (
    <Box sx={{ width: '100%', my: 3 }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <TableContainer>
        <Table sx={{ minWidth: 750 }}>
          <TableHead sx={{ background: '#EFEFEF' }}>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={'center'}
                  padding={'normal'}
                  sortDirection={orderByField === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderByField === headCell.id}
                    direction={orderByField === headCell.id ? order : 'asc'}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    <Typography
                      variant='body1'
                      sx={{
                        fontWeight: 'bold',
                        color: (theme) => theme.palette.middleblue.main,
                      }}
                    >
                      {headCell.label}
                    </Typography>
                    {orderByField === headCell.id ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length &&
              rows
                .slice()
                .sort(getComparator(order, orderByField))
                .map((row, index) => (
                  <TableRow hover key={index}>
                    <TableCell component='th' id={row.dataid} scope='row'>
                      <Button
                        variant='outlined'
                        onClick={() =>
                          updateMapCenter(
                            row.latitude,
                            row.longitude,
                            row.dataid
                          )
                        }
                      >
                        Show on Map
                      </Button>
                    </TableCell>
                    <TableCell align='center'>{row.Category}</TableCell>
                    <TableCell align='center'>
                      {row.LocationName}
                    </TableCell>
                    <TableCell align='center'>
                      {row.Location}
                    </TableCell>
                    <TableCell align='center'>
                      {row.Details}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
    </Box>

              ) : (
                <></>
              )}
              </>
  );
}