/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import IncidentLayout from '../components/Branding/IncidentLayout';
import { titleContext } from '../contexts/TitleContext';
import { breadCrumbsContext } from '../contexts/BreadCrumbsContext';
import { incidentsContext } from '../contexts/IncidentsContext';
import PressItem from '../components/PressReleases/PressItem';
import DisasterRecoveryMap from '../components/DisasterRecoveryMap';

export default function DisasterRecovery({ incidentIndex }) {
  const { updatePageTitle, updatePageHeading } = useContext(titleContext);
  const { pushBreadCrumbs } = useContext(breadCrumbsContext);
  const { incidents } = useContext(incidentsContext);
  const [disasterRecoveryUpdates, setDisasterRecoveryUpdates] = useState([]);

  let url;

  if (process.env.REACT_APP_ENV == 'local') {
    url = process.env.REACT_APP_LOCAL_URL
  } else {
    url = 'https://api.alachuacounty.us/incidents-api'
  }


  useEffect(() => {
    updatePageTitle(`${incidents[incidentIndex].name} | Disaster Recovery`);
    updatePageHeading(incidents[incidentIndex].name);
    if (incidents && incidents.length && incidents[0].updates.disasterRecoveryUpdates) {
      setDisasterRecoveryUpdates(incidents[incidentIndex].updates.disasterRecoveryUpdates);
    }
    pushBreadCrumbs({
      crumb: incidents[incidentIndex].name,
      link: `/incidents/${incidents[incidentIndex].urlName}/`,
    });
  }, []);

  return (
    <IncidentLayout title='Recovery Resources'>
      <div id='disasterRecoveryMap'>
        <Grid container>
          <DisasterRecoveryMap incidentIndex={incidentIndex} />
        </Grid>
      </div>
      <div id='disasterRecoveryBlurb'>
        <Grid>
          <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
            Disaster Recovery Updates
          </Typography>
        </Grid>
        <Grid container xs={12} paddingBottom={6} justifyContent='flex-start'>
          {disasterRecoveryUpdates && disasterRecoveryUpdates.length > 0 ? (

            disasterRecoveryUpdates.map((data, index) => (
              <PressItem data={data} spacing={10} expanded={true} justifyContent={'flex-start'} index={index} />
            ))
          ) : (
            <Typography variant='h6'>No Disaster Recovery Updates</Typography>
          )}
        </Grid>
      </div>
    </IncidentLayout>
  );
}
