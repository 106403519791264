import { Grid, Typography } from '@mui/material';
import React, {useContext} from 'react';

import EmergencyBlurb from '../EmergencyBlurb';
import { makeStyles } from '@mui/styles';

//import { incidentsContext } from '../../contexts/IncidentsContext';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  title2: {
    fontWeight: 'bold',
  },
  hidden: {
    [theme.breakpoints.down('md')]: {
      //display: 'none',
    },
  },
}));


const blurbTitle = 'Sign Up for Emergency Alerts';
const blurbText = 'Text "ALACHUA" to 888-777 to receive real-time County updates during a large-scale incident or emergency';
const blurbTitle2 = "Don't see the information you're looking for?";
const blurbText2 = 'Call 311';

function EmergencyBlurbSection(rows){
  const classes = useStyles();
  //const { incidents } = useContext(incidentsContext);
  return (
    <Grid
    item
    container
    xs={12}
    md={rows?12:3}
    justifyContent='flex-end'
    className={classes.hidden}
  >
    <Grid item xs={12}>
      <EmergencyBlurb title={blurbTitle} text={blurbText} removePadding={true} fullWidth={rows}/>

     { !window.location.pathname.includes("helene") && (<EmergencyBlurb title={blurbTitle2} text={blurbText2} fullWidth={rows} />)}
    </Grid>

  </Grid>
  );
}

export default function IncidentLayout({
  children,
  title = 'Page Title',
  title2,
  rows,
  incidentIndex
}) {
  const classes = useStyles();
  return (
    <Grid container xs={12} spacing={3} justifyContent='center' ml={0}>
      <Grid item xs={12}>
        <Typography
          variant='h4'
          textAlign='center'
          sx={{ my: 4 }}
          className={classes.title}
        >
          {title}
        </Typography>
        {title2 && (
          <Typography
            variant='h4'
            textAlign='center'
            sx={{ my: 4 }}
            className={classes.title2}
          >
            {title2}
          </Typography>
        )}
      </Grid>
      <Grid container xs={12} md={12} lg={10} justifyContent='center'>
        <Grid item xs={rows?12:10} md={rows?12:8} mb={'5% !important'}>
          {children}
        </Grid>
        
        {!rows && (
          <>
          {EmergencyBlurbSection(rows)}
          </>
          )}
      </Grid>
      {rows && (
        <Grid container xs={12} justifyContent='center'>
          {EmergencyBlurbSection(rows)}
        </Grid>
      )}
    </Grid>
  );
}
