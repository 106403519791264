import React, { useContext, useEffect, useState } from 'react';
import { incidentsContext } from '../contexts/IncidentsContext';
import EmergencyBannerItem from './EmergencyBannerItem';

export default function EmergencyBanner({ incidentIndex }) {
    const { incidents, banners } = useContext(incidentsContext);
    const [bannerData, setBannerData] = useState([]);

    let setBanners = ()=> {
        setBannerData(banners[0])
    }

    useEffect(()=>{
        setBanners();
    })

    return (
      <>
      {bannerData ? (
        <ul>
        {bannerData.map((banner, index) => (
          <EmergencyBannerItem banner={{banner}} incidents={{incidents}} incidentIndex={{incidentIndex}} key={index} />
        ))}
      </ul>
    ) : (
      <></>
    )}
    </>
    );
}