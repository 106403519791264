import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography,
  } from '@mui/material';
  import React, { useState } from 'react';
  import { visuallyHidden } from '@mui/utils';
  import { makeStyles } from '@mui/styles';
  import clsx from 'clsx';
  
  const useStyles = makeStyles((theme) => ({
    statusGreen: {
      background: theme.palette.green.main,
    },
    statusRed: {
      background: theme.palette.red.main,
    },
    statusSandbags: {
      background: theme.palette.sandbags.main,
    },
    statusCoolingCenter: {
      background: theme.palette.coolingcenter.main,
    },
    statusTarpDistribution: {
      background: theme.palette.tarpdistribution.main,
    },
    statusDeviceChargingLocation: {
      background: theme.palette.devicecharginglocation.main,
    },
    statusHotMealDistribution: {
      background: theme.palette.hotmealdistribution.main,
    },
    statusDisasterRecoveryCenter: {
      background: theme.palette.disasterrecoverycenter.main,
    },
    statusDSNAP: {
      background: theme.palette.dsnap.main,
    },
    statusPointOfDistribution: {
      background: theme.palette.pointofdistribution.main,
    },
    statusFeedingDistributionSite: {
      background: theme.palette.feedingdistributionsite.main,
    },
    statusGrey: {
      background: theme.palette.grey.main,
    },
    spanStyles: {
      color: theme.palette.white.main,
      display: 'block',
      fontWeight: 'bold',
      textTransform: 'Uppercase',
      padding: '0 4px'
    },
  }));


  
  function descendingComparator(a, b, orderByField) {
    if (b[orderByField] < a[orderByField]) {
      return -1;
    }
    if (b[orderByField] > a[orderByField]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderByField) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByField)
      : (a, b) => -descendingComparator(a, b, orderByField);
  }
  
  export default function DistributionSite({ headCells, rows, updateMapCenter }) {
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderByField, setOrderByField] = useState('');

    const styleValues = {
      FeedingDistributionSite: classes.statusFeedingDistributionSite,
      Sandbags: classes.statusSandbags,
      CoolingCenter: classes.statusCoolingCenter,
      TarpDistribution:classes.statusTarpDistribution,
      DeviceChargingLocation:classes.statusDeviceChargingLocation,
      HotMealDistribution:classes.statusHotMealDistribution,
      DisasterRecoveryCenter:classes.statusDisasterRecoveryCenter,
      DSNAP:classes.statusDSNAP,
      PointOfDistribution:classes.statusPointOfDistribution,
      FeedingDistributionSite:classes.statusFeedingDistributionSite
  
    }
  
    const handleRequestSort = (property) => {
      const isAsc = orderByField === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderByField(property);
    };
  
    return (
      <Box sx={{ width: '100%', my: 3 }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }}>
            <TableHead sx={{ background: '#EFEFEF' }}>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={'center'}
                    padding={'normal'}
                    sortDirection={orderByField === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderByField === headCell.id}
                      direction={orderByField === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      <Typography
                        variant='body1'
                        sx={{
                          fontWeight: 'bold',
                          color: (theme) => theme.palette.middleblue.main,
                        }}
                      >
                        {headCell.label}
                      </Typography>
                      {orderByField === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length !=0 &&
                rows
                  .slice()
                  .sort(getComparator(order, orderByField))
                  .map((row, index) => (
                    <TableRow hover key={index}>
                      <TableCell component='th' id={row.dataid} scope='row'>
                        <Button
                          variant='outlined'
                          onClick={() =>
                            updateMapCenter(
                              row.latitude,
                              row.longitude,
                              row.dataid
                            )
                          }
                        >
                          Show on Map
                        </Button>
                      </TableCell>
                      <TableCell align='center'>

                      <span
                          className={clsx(
                            classes.spanStyles,
                            styleValues[row.Category.replace(/\s/g, '')]
                              ? styleValues[row.Category.replace(/\s/g, '')]
                              : classes.statusGrey
                          )}
                        >
                          {row.Category}
                        </span>
                        </TableCell>
                      <TableCell align='center'>
                        {row.LocationName}
                      </TableCell>
                      <TableCell align='center'>
                        {row.Location}
                      </TableCell>
                      <TableCell align='center'>
                        {row.Details}
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Paper>
      </Box>
    );
  }