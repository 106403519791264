import { Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
}));

/* const bannerBoxShadow = {
  boxShadow: '0 -5px 5px rgb(18 38 76 / 15%);
}; */

export default function EmergencyBanner({ banner, incidents, incidentIndex }) {
  const classes = useStyles();
  // let bannerIncidentName = banner.banner.IncidentName.toLowerCase().replace(/\s/g, '');
  // let urlName = incidents.incidents[incidentIndex.incidentIndex].urlName;
  // let bannerData = banner.banner;
  // let bannerType = banner.banner.BannerType;

  const [bannerIncidentName, setBannerIncidentName] = useState();
  const [urlName, setUrlName] = useState();
  const [bannerData, setBannerData] = useState();
  const [bannerType, setBannerType] = useState();

  let setBannerInfo = () => {
    if(incidentIndex.incidentIndex){
    setBannerIncidentName(banner.banner.IncidentName.toLowerCase().replace(/\s/g, ''));
     setUrlName(incidents.incidents[incidentIndex.incidentIndex].urlName);
     setBannerData(banner.banner);
     setBannerType( banner.banner.BannerType); 
    }
  }

  useEffect(()=>{
    setBannerInfo();
})

  return (
    <div>
       {incidentIndex.incidentIndex &&  bannerType == 'Warning' && bannerIncidentName.includes(urlName) ? ( 
        <Grid
          container
          xs={12}
          justifyContent='center'
          alignContent='center'
          backgroundColor={bannerData.BackgroundColor}
          height='100%'
          p={2}
          boxShadow='0 -5px 5px rgb(18 38 76 / 15%)'
        >
          <Grid item alignSelf='center' display='flex' alignContent='center'>
            <InfoOutlinedIcon verticalalign='middle' />
            <Typography
              variant={{ xs: 'h5', sm: 'h4' }}
              textAlign='left'
              className={classes.title}
            >
              {/* Currently Monitoring {incidents[0].name} - View Important information
            about this storm */}
              {bannerData.BannerText}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        null
      )}
    </div>
  );
}
