import React, { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ACRButton from '../ACRButton';

export default function InfoBannerItem({ banners, incidents, incidentIndex }) {
let bannerIncidentName = banners.banner.IncidentName.toLowerCase().replace(/\s/g, '');;
let urlName = incidents.incidents[incidentIndex.incidentIndex].urlName;
let bannerData = banners.banner;
let bannerType = banners.banner.BannerType;

  return (
    <div>
    {incidentIndex.incidentIndex && bannerType == 'Info' && bannerIncidentName.includes(urlName) ? (
      <Grid
        container
        xs={12}
        alignSelf=''
        justifyContent='center'
        sx={{
          height: 200,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          bgcolor: `${bannerData.BackgroundColor}!important`,
          paddingLeft: '1rem',
          paddingRight: '1rem',
        }}
      >
        <Grid item justifySelf='center'>
              <>
                <Typography justifyContent='center' variant='h4' pl={2} pr={2} pb={2}>
                {bannerData.BannerText}:{' '}
                </Typography>
                <ACRButton
                  text={bannerData.HyperlinkText}
                  link={bannerData.BannerLink}
                  size='jumbo'
                  externalLink={true}
                />
              </>
        </Grid>
      </Grid>
    ) : (
      null
    )}
    </div>
  );
}