import React, { useContext, useEffect, useState } from 'react';
import { Grid, Divider, Typography } from '@mui/material';
import { titleContext } from '../contexts/TitleContext';
import { breadCrumbsContext } from '../contexts/BreadCrumbsContext';
import LatestUpdates from '../components/IncidentHome/LatestUpdates';
import InfoBanner from '../components/IncidentHome/InfoBanner'
import { incidentsContext } from '../contexts/IncidentsContext';
import EmergencyBanner from '../components/EmergencyBanner';
import { makeStyles } from '@mui/styles';
import { Link } from '@mui/material';
import DisasterRecoveryMap from '../components/DisasterRecoveryMap';
import EmergencyBlurb from '../components/EmergencyBlurb';
import Shelter from './Shelter';

const useStyles = makeStyles((theme) => ({

  link: {
    color: theme.palette.middleblue.main,
  },

}));


export default function IncidentHome({ incidentIndex }) {
  const classes = useStyles();
  const { incidents, banners } = useContext(incidentsContext);
  const { updatePageTitle, updatePageHeading } = useContext(titleContext);
  const { pushBreadCrumbs } = useContext(breadCrumbsContext);
  const [hasWarningBanner, setHasWarningBanner] = useState(false);
  const [hasInfoBanner, setHasInfoBanner] = useState(false);
  let urlName = incidents[incidentIndex].urlName;
  const [phase, setPhase] = useState();

  let checkForBanners = async () => {
    await banners[0].forEach(banner => {
      incidents.forEach(incident => {
        if (banner.BannerType == 'Info' && urlName == banner.IncidentName.toLowerCase().replace(/\s/g, '')) {
          setHasInfoBanner(true);
        } else if (banner.BannerType == 'Warning' && urlName == banner.IncidentName.toLowerCase().replace(/\s/g, '')) {
          setHasWarningBanner(true);
        }
      });
    });

  }

  const blurbTitle = 'Sign Up for Emergency Alerts';
  const blurbText =
    'Text "ALACHUA" to 888-777 to receive real-time County updates during a large-scale incident or emergency';
  const blurbTitle2 = "Don't see the information you're looking for?";
  const blurbText2 = 'Call 311';

  useEffect(() => {
    updatePageTitle(`${incidents[incidentIndex].name} | Home`);
    updatePageHeading(incidents[incidentIndex].name);
    checkForBanners();
    setPhase(incidents[incidentIndex].phase)
    pushBreadCrumbs({
      crumb: incidents[incidentIndex].name,
      link: `/incidents/${incidents[incidentIndex].urlName}`,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {hasWarningBanner ? (
      <EmergencyBanner incidentIndex={incidentIndex} />
    ) : (

      null
    )}

      <Grid container spacing={0} xs={12} justifyContent='center'>

        {hasInfoBanner ? (
          <Grid item xs={12} pt={3} pb={3}>
            <InfoBanner incidentIndex={incidentIndex} />
          </Grid>
        ) : (
          null
        )
}

        <Grid item xs={12} md={10} pt={3} pb={3}>
          <LatestUpdates incidentIndex={incidentIndex} />
          <Divider variant='middle' />
        </Grid>

        {incidents[incidentIndex].phase == 'Recovery' ? (
          <Grid xs={10}>
            <DisasterRecoveryMap incidentIndex={incidentIndex} />
          </Grid>
        ) : phase == 'Prepare' ? (
          <Grid xs={10}>
            <Shelter incidentIndex={incidentIndex}></Shelter>
          </Grid>  
        ) : (
          null
        )
        }

           <Grid item xs={12} pt={3} pb={3}>
          <EmergencyBlurb
            fullWidth={true}
            title={blurbTitle}
            text={blurbText}
          //removePadding={true}
          />
          {incidents[incidentIndex].urlName !== "helene" &&
            <EmergencyBlurb
              fullWidth={true}
              title={blurbTitle2}
              text={blurbText2}
            />
          }
        </Grid>
      </Grid>
    </>
  );
}
