/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Checkbox, FormGroup, FormControlLabel, Alert, Box, LinearProgress} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import Geocode from 'react-geocode';
import { useParams, useNavigate} from 'react-router-dom';

import IncidentLayout from '../components/Branding/IncidentLayout';
import Map from '../components/Map';
import { titleContext } from '../contexts/TitleContext';
import { breadCrumbsContext } from '../contexts/BreadCrumbsContext';
import DistributionTable from '../components/Tables/DistributionSite';
import { incidentsContext } from '../contexts/IncidentsContext';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import FilterAltIcon from '@mui/icons-material/FilterAlt';


Geocode.setApiKey(`AIzaSyBRbdKmyFU_X9r-UVmsapYMcKDJQJmQpAg`);
Geocode.setLocationType('ROOFTOP');

const headCells = [
  {
    id: 'dataid',
    label: '',
  },
  {
    id: 'Category',
    label: 'Type',
  },
  {
    id: 'LocationName',
    label: 'Location',
  },
  {
    id: 'Location',
    label: 'Address',
  },
  {
    id: 'Details',
    label: 'Additional Details',
  },
];

const useStyles = makeStyles((theme) => ({
  statusSandbags: {
    background: theme.palette.sandbags.main,
  },
  statusCoolingCenter: {
    background: theme.palette.coolingcenter.main,
  },
  statusTarpDistribution: {
    background: theme.palette.tarpdistribution.main,
  },
  statusDeviceChargingLocation: {
    background: theme.palette.devicecharginglocation.main,
  },
  statusHotMealDistribution: {
    background: theme.palette.hotmealdistribution.main,
  },
  statusDisasterRecoveryCenter: {
    background: theme.palette.disasterrecoverycenter.main,
  },
  statusDSNAP: {
    background: theme.palette.dsnap.main,
  },
  statusPointOfDistribution: {
    background: theme.palette.pointofdistribution.main,
  },
  statusFeedingDistributionSite: {
    background: theme.palette.feedingdistributionsite.main,
  },
  statusGrey: {
    background: theme.palette.grey.main,
  },
  spanStyles: {
    color: theme.palette.white.main,
    display: 'block',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    textTransform: 'Uppercase',
    padding: '0 4px'
  },
}));



export default function DistributionSite({ incidentIndex }) {
  const params = useParams();
  const navigate = useNavigate();
  const { updatePageTitle, updatePageHeading } = useContext(titleContext);
  const { pushBreadCrumbs } = useContext(breadCrumbsContext);
  const { incidents } = useContext(incidentsContext);
  const [center, setCenter] = useState({ lat: 29.651634, lng: -82.324829 });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [distributionSiteLocationData, setDistributionSiteLocationData] = useState([]);
  const [selectedDistributionSiteLocationData, setSelectedDistributionSiteLocationData] = useState([]);
  const [siteTypes, setSiteTypes]= useState([]);
  const [selectedSites, setSelectedSites]= useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  const styleValues = {
    FeedingDistributionSite: classes.statusFeedingDistributionSite,
    Sandbags: classes.statusSandbags,
    CoolingCenter: classes.statusCoolingCenter,
    TarpDistribution:classes.statusTarpDistribution,
    DeviceChargingLocation:classes.statusDeviceChargingLocation,
    HotMealDistribution:classes.statusHotMealDistribution,
    DisasterRecoveryCenter:classes.statusDisasterRecoveryCenter,
    DSNAP:classes.statusDSNAP,
    PointOfDistribution:classes.statusPointOfDistribution,
    FeedingDistributionSite:classes.statusFeedingDistributionSite

  }

  let url;
  
  if(process.env.REACT_APP_ENV == 'local'){
    url = process.env.REACT_APP_LOCAL_URL
  } else {
    url = 'https://api.alachuacounty.us/incidents-api'
  }

  const getDistributionSiteLocationData = async () => {
    try {
      const test = await axios.get(`${url}/distributionLocations/activeDisasterRecoveryLocations`);

      for (let index in test.data[0]) {
        const response = await Geocode.fromAddress(test.data[0][index].Location);
        if (response.results[0]) {
          const { lat, lng } = response.results[0].geometry.location;
          test.data[0][index].latitude = lat;
          test.data[0][index].longitude = lng;
          test.data[0][index].dataid = test.data[0][index].MondayID;
          test.data[0][index].label= test.data[0][index].LocationName;
          test.data[0][index].address = test.data[0][index].Location;
        }
      }

      setDistributionSiteLocationData(test.data[0]);
      setIsLoading(false);

      const exSiteTypes = [...new Set(test.data[0].map(item=> item.Category))];
     /* exSiteTypes.push("Cooling Center");
      exSiteTypes.push("Tarp Distribution");
      exSiteTypes.push("Hot Meal Distribution");
      exSiteTypes.push("DSNAP");
      exSiteTypes.push("Point Of Distribution");
      exSiteTypes.push("Device Charging Location");
      exSiteTypes.push("Disaster Recovery Center");
      exSiteTypes.push("Feeding Distribution Site");
      exSiteTypes.push("New label");*/
      setSiteTypes(exSiteTypes);
      
      const allSiteTypes = exSiteTypes.map(type=>type.toLowerCase());
      var urlSelectedTypes = allSiteTypes;

      if(params.Categories)
      {
        var sitesFromurl = params.Categories.toLowerCase().split('-');
 
        urlSelectedTypes = allSiteTypes.filter(item => sitesFromurl.includes(item.replace(/\s/g, '').toLowerCase()));

      }

      //if intersection is empty, revert to all types
       if(urlSelectedTypes.length === 0){
        urlSelectedTypes = allSiteTypes;
  
       }

      setSelectedSites(urlSelectedTypes);

      const filteredData = test.data[0].filter(item => urlSelectedTypes.includes(item.Category.toLowerCase()));
      setSelectedDistributionSiteLocationData(filteredData);


    } catch (error) {
      console.log(error);
    }
  };

  const updateMapCenter = (lat, lng, id) => {
    setCenter({ lat: parseFloat(lat), lng: parseFloat(lng) });
    updateSelectedMarker(id);
  };

  const updateSelectedMarker = (id) => {
    setSelectedMarker(id);
  };

  const handleSiteFilter =(type)=>{



   const newSelection = selectedSites.includes(type.toLowerCase())? selectedSites.filter(curr => curr !== type) : [...selectedSites, type];
   setSelectedSites(newSelection);


   const filteredData = distributionSiteLocationData.filter(item => newSelection.includes(item.Category.toLowerCase()));

   setSelectedDistributionSiteLocationData(filteredData);

   navigate('/incidents/'+incidents[incidentIndex].urlName+'/distributionsites/'+newSelection.join('-').replace(/\s/g, ''), { replace: true });

  };

  useEffect(() => {
    getDistributionSiteLocationData();
    updatePageTitle(`${incidents[incidentIndex].name} | Distribution Site`);
    updatePageHeading(incidents[incidentIndex].name);

    pushBreadCrumbs({
      crumb: incidents[incidentIndex].name,
      link: `/incidents/${incidents[incidentIndex].urlName}/`,
    });
  }, []);

  return (
    <IncidentLayout title='Distribution Sites' rows={true}>
      <div id='distributionSiteMap'>
      <Grid container>
        {distributionSiteLocationData && distributionSiteLocationData.length > 0 ? (
          <>
         
            <Grid item xs={12}>
              <Map data={selectedDistributionSiteLocationData} center={center} selectedMarker={selectedMarker} />
            </Grid>
         
            <Grid item xs={12} pt={2}> 
              <Box
              component="section" 
              sx={{ borderRadius:'8px', boxShadow:3, width:'100%'}}>   
              <Box p={2}>  
              <Grid container>
                <Grid item xs={12} sm={1}> 
                   <Typography sx={{display: 'flex',alignItems: 'center',flexWrap: 'wrap'}}> <FilterAltIcon></FilterAltIcon> Filter: </Typography>    
                </Grid>
                <Grid item xs={12} sm={11} >
                    <FormGroup sx={{display:'flex', flexDirection:'row'}}>
                      {siteTypes.map(type => (
                    <FormControlLabel 
                    key={type} 
                    control={<Checkbox  
                      size="medium"
                      checked={selectedSites.includes(type.toLowerCase())}
                      onChange={()=> handleSiteFilter(type.toLowerCase())}/>} 
                      label={      <span
                        className={clsx(
                          classes.spanStyles,
                          styleValues[type.replace(/\s/g, '')]
                            ? styleValues[type.replace(/\s/g, '')]
                            : classes.statusGrey
                        )}
                      >
                        {type}
                      </span>} /> ))}
                    </FormGroup>
                </Grid>
              </Grid>
              </Box>
              </Box> 
            </Grid>

            <Grid item xs={12}>
              <DistributionTable headCells={headCells} rows={selectedDistributionSiteLocationData} updateMapCenter={updateMapCenter} />
            </Grid> 

            {!selectedDistributionSiteLocationData || selectedDistributionSiteLocationData.length <= 0 && (
              <Grid item xs={12}>
              <Alert severity="warning"> Please select a site type to view available locations </Alert>
              </Grid>
            )}
          
      
          </>
        ) : (
          <Grid item xs={12}>

            {isLoading && 
                (<Box sx={{ width: '100%' }}>
                  <LinearProgress />
                </Box>)}
            {!isLoading && distributionSiteLocationData.length==0 &&
                (<Typography variant='h6' sx={{ fontWeight: 'bold' }}>
              There are currently no centers open. This page will be updated as the incident progresses. Please check again later for distribution sites.
                </Typography>
            )}
          </Grid>
        )}
      </Grid>
      </div>
    </IncidentLayout>
  );
}
