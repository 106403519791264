import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { incidentsContext } from '../../contexts/IncidentsContext';
import InfoBannerItem from './InfoBannerItem';

export default function InfoBanner({ incidentIndex }) {
    const { incidents, banners } = useContext(incidentsContext);
    const [bannerData, setBannerData] = useState(banners[0]);

    let setBanners = ()=> {
        setBannerData(banners[0])
    }

    useEffect(()=>{
        setBanners();
    })

    return (
        <ul>
        {bannerData.map((banner, index) => (
          <InfoBannerItem banners={{banner}} incidents={{incidents}} incidentIndex={{incidentIndex}} key={index} />
        ))}
      </ul>
    );
}